import { storyblokEditable } from "@storyblok/react";
import { Box, Button, Center, Flex, GridItem, LinkBox, LinkOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface LocationFeaturedJournalsProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationFeaturedJournals = ({ blok, globalState, locationState }: LocationFeaturedJournalsProps):JSX.Element => {
  const router = useRouter();
  const [isJournalsPage, setIsJournalsPage] = useState<boolean>(false);

  useEffect(() => {
    if (router.pathname.includes('/trip-journals')) {
      setIsJournalsPage(true);
    }
  }, [router.pathname])

  return (
    locationState.restricted === 0 && <Flex 
      {...storyblokEditable(blok)}
      direction="column" 
      mt="10"
    >
      <Text 
        as="h2" 
        textAlign={'center'} 
        color="brand.green.600"
        mb="5"
      >
        Experience a Mission Trip in {locationState?.theName}
      </Text>

      <Flex 
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        gap="5"
      >
        {
          locationState?.recommendedJournals && locationState?.recommendedJournals.map((journal, index) => {
            if (index >= 3) return null;
            return <FeaturedJournal journal={journal} key={journal.tripID} />;
          })
        }
      </Flex>
      
      {
        !isJournalsPage &&
        <Center mt="5">
          <Link
            href={`/mission-trips/${router.query.slug}/trip-journals`}
            passHref 
          >
            <Button
              variant="solid"
              colorScheme="brand.green"
              as="a"
              sx={{'&:hover': {textDecoration: 'none'}}}
              maxWidth="md"
            >
              View All Past Journals
            </Button>
          </Link>
        </Center>
      }
    </Flex>
  );
}

export const FeaturedJournal = ({ journal }: { journal: CurrentTripJournal }) => {
  let photoUrl;

  if (journal.hasGroupThumbnail) {
    photoUrl = `https://images.ppm.org/upload/groupphotos/thumb/${journal.tripID}.jpg`;
  } else if (journal.hasGroupPhoto) {
    photoUrl = `https://images.ppm.org/upload/groupphotos/${journal.tripID}.jpg`;
  } else {
    photoUrl = `/images/praying-pelican-logo-globe.png`;
  }

  return (
    <LinkBox
      key={journal.tripID}
      transition="0.2s ease-in-out"
      bg="white"
      borderColor="brand.green.600"
      borderWidth="1px"
      boxShadow="lg"
      overflow="hidden"
      cursor={'pointer'}
      width="300px"
      rounded="xl"
      _hover={{
        transform: 'scale(1.02)',
      }}
    >
      <Link href={`/mission-trip-journals/${journal.tripID}`} passHref >
        <LinkOverlay
          sx={{
            '&:hover': {
              textDecoration: "none",
            }
          }}
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="flex-start"
            minH="325px"
          >
            <Image
              src={photoUrl}
              alt={journal.groupname}
              width={journal.hasGroupPhoto ? '300px': '200px'}
              height={'200px'}
              objectFit={'cover'}
            />
            <Flex 
              p="2"
              direction="column"
              justifyContent={'center'}
              flexGrow="1"
            >
              <Text as="h3" fontSize="lg" textAlign={'center'} fontWeight='bold' lineHeight={1}>
                {journal.groupname}
              </Text>
              <Text
                as="p"
                textAlign={'center'}
                mb="0"
              >
                <em>{journal.homeTown}</em>
              </Text>
              {
                journal.communityList.length > 0 &&
                <Text as="p" mb="0" textAlign={'center'} lineHeight="1">
                  Serving in {journal.communityList}
                </Text>
              }
              <Text as="p" mb="0" textAlign={'center'}>
                {journal.locationName}
              </Text>
              <Text as="p" mb="0" textAlign={'center'}>
                {journal.tripdate}
              </Text>
            </Flex>
          </Flex>
        </LinkOverlay>
      </Link>
    </LinkBox>
  )
}

export default LocationFeaturedJournals;
